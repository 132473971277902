<template>
  <div class="page-apply-by-me">
    <h2>我的申请</h2>
    <van-list v-model="loading"
              :finished="finished"
              error-text="请求失败，点击重新加载"
              finished-text="没有更多了"
              :error="error"
              @load="onLoad">
      <div class="apply-list van-clearfix"
           inset
           v-for="(item,idx) in applyList"
           :key="idx">
        <van-cell size="large"
                  title="内容"
                  :value="item.processDefinitionName" />
        <van-cell size="large"
                  title="创建时间"
                  :value="item.startTime">
          <template slot-scope="data">
            {{ data | date }}
          </template>
        </van-cell>

        <van-cell size="large"
                  title="状态">
          <van-tag size="large"
                   :type="item.state == 'COMPLETED' ?'success':item.state=='EXTERNALLY_TERMINATED'?'danger' : 'warning'">
            {{ item.state == "COMPLETED" && "已完成" ||''}}
            {{ item.state=='EXTERNALLY_TERMINATED'&&'已取消'||''}}
            {{item.state =='ACTIVE'&&"正在进行"||'' }}</van-tag>
        </van-cell>

        <van-cell size="large"
                  center>
          <template>
            <div style="text-align: right;">
              <van-button style="width:3rem;"
                          size="small"
                          round
                          type="info"
                          @click="showBpmnInstanceDiagram(item)">查看</van-button>

              <van-button style="width:3rem;margin-left:1rem;"
                          size="small"
                          round
                          v-if="item.state != 'COMPLETED'"
                          type="warning"
                          :disabled="!item.allowRevoke"
                          @click="revokeClassreserve(item,idx)">取消申请</van-button>
            </div>
          </template>
        </van-cell>
      </div>
    </van-list>

    <van-empty v-if="!applyList.length"
               description="暂无申请" />

    <bpmnInstanceDiagram ref="bpmnInstanceDiagram"></bpmnInstanceDiagram>
  </div>
</template>
<script>
import { SUCCESS_CODE } from "@/apis/notification.js";
import "@/assets/less/views/approval/applyByMe.less";
import { sortBy, pick, assign } from "lodash-es";

import Viewer from "bpmn-js/lib/NavigatedViewer";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import { mapState } from "vuex";
import bpmnInstanceDiagram from "./bpmnInstanceDiagram.vue";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      loading: false,
      error: false,
      finished: false,
      applyList: [],
      formList: [],
      diagramDialogVisible: false,
      bpmnViewer: null,
      bpmnInfo: {
        name: "",
        childActivityInstances: "",
        activityName: "",
        xml: "",
        state: "",
        processDefinitionId: "",
        processInstanceId: ""
      },
      pager: {
        pageNumber: 1,
        pageSize: 20,
        totalRecords: 0
      }
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    async init() {
      await this.getFormList();
    },
    async getFormList() {
      let ret = await this.$api.getFormList({ async: false });
      if (ret.code != SUCCESS_CODE) return;
      this.formList = ret.data;
    },
    onLoad() {
      this.getHistoryTaskList();
    },
    async revokeClassreserve(item, idx) {
      if (!item.allowRevoke) return;

      let confirm = await Dialog.confirm({
        title: '撤回申请',
        message: '是否撤回该申请？',
      })
        .then(() => { return true })
        .catch(() => { return false });
      if (!confirm) return;

      let id = item.id;
      let ret = await this.$api.deleteProcessIntanceById({ placeholder: { id } });
      if (ret.code != SUCCESS_CODE) return;
      this.$utils.success("申请撤回成功");
      this.approvalList.splice(idx, 1);
    },
    async getHistoryTaskList() {
      this.loading = true;
      let ret = await this.$api.getApplyHistoryTaskList({
        placeholder: {
          uid: this.userInfo.userId
        },
        params: {
          pageSize: this.pager.pageSize,
          pageNumber: this.pager.pageNumber - 1
        }
      });
      if (ret.code != SUCCESS_CODE) {
        this.loading = false;
        this.error = true;
        return;
      }

      this.pager.pageNumber++;

      ret.data.forEach(item => {
        let target = this.formList.find(it => {
          return it.name == item.processDefinitionName;
        });
        if (target) {
          item._processDefinitionName = item.processDefinitionName;
          item.processDefinitionName = target.description;
        }
      });
      this.pager.totalRecords = ret.totalRecords;
      let totalPages = Math.ceil(ret.totalRecords / this.pager.pageSize);
      console.log(totalPages, this.pager.pageNumber)

      this.finished = this.pager.pageNumber > totalPages;
      this.pager.pagerNumber++;
      this.loading = false;

      this.applyList = this.applyList.concat(ret.data);
    },
    showBpmnInstanceDiagram(row) {
      this.$refs["bpmnInstanceDiagram"].viewDetail(row);
    }
  },
  components: {
    bpmnInstanceDiagram
  }
};
</script>

<style lang="less"></style>
